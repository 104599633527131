.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* .table.table thead th {
	font-weight: 600 !important;
	color: #1d1d1d !important;
	border-right: 2px solid #f49c10;
	margin-bottom: 1rem;
} */

tr {
	border-color: #cccbcb !important;
}

/* MODAL */

.customOverlay {
	background: rgba(0, 0, 0, 0.5) !important;



}
.editProfilleModal {
	min-width: 35.25rem;
	max-width: 35.25rem !important;
	border-radius: 20px;
	padding: 0 !important;
}
.customModal {
	border-radius: 3.125rem;
	z-index: 10;
}
.DeleteModal {
	border-radius: 3.125rem;
	min-width: 50rem !important;
	max-width: 50rem !important;
	z-index: 10;
}
.successModal {
	border-radius: 3.125rem;
	min-width: 50rem !important;
	max-width: 50rem !important;
}
@media screen and (min-width: 1819px) and (max-width: 1920px) {
	.customModal {
		min-width: 80rem !important;
		max-width: 80rem !important;
	}
	.DeleteModal{
		min-width: 80rem !important;
		max-width: 80rem !important;
	}
}
@media screen and (min-width: 1719px) and (max-width: 1820px) {
	.customModal {
		min-width: 80rem !important;
		max-width: 80rem !important;
	}
	.DeleteModal{
		min-width: 50rem !important;
		max-width: 50rem !important;
	}
}
@media screen and (min-width: 1619px) and (max-width: 1720px) {
	.customModal {
		min-width: 75rem !important;
		max-width: 75rem !important;
	}
	.DeleteModal{
		min-width: 45rem !important;
		max-width: 45rem !important;
	}
}
@media screen and (min-width: 1519px) and (max-width: 1620px) {
	.customModal {
		min-width: 80rem !important;
		max-width: 80rem !important;
	}
	.DeleteModal{
		min-width: 50rem !important;
		max-width: 50rem !important;
	}
}
@media screen and (min-width: 1419px) and (max-width: 1520px) {
	.customModal {
		min-width: 65rem !important;
		max-width: 65rem !important;
	}
	.DeleteModal{
		min-width: 50rem !important;
		max-width: 50rem !important;
	}
}
@media screen and (min-width: 1319px) and (max-width: 1420px) {
	.customModal {
		min-width: 65rem !important;
		max-width: 65rem !important;
	}
	.DeleteModal{
		min-width: 45rem !important;
		max-width: 45rem !important;
	}
}
@media screen and (min-width: 1219px) and (max-width: 1320px) {
	.customModal {
		min-width: 70rem !important;
		max-width: 70rem !important;
	}
	.DeleteModal{
		min-width: 40rem !important;
		max-width: 40rem !important;
	}
}
@media screen and (min-width: 1119px) and (max-width: 1220px) {
	.customModal {
		min-width: 55rem !important;
		max-width: 55rem !important;
	}
	.DeleteModal{
		min-width: 45rem !important;
		max-width: 45rem !important;
	}
}
@media screen and (min-width: 1019px) and (max-width: 1120px) {
	.customModal {
		min-width: 50rem !important;
		max-width: 50rem !important;
	}
	.DeleteModal{
		min-width: 50rem !important;
		max-width: 50rem !important;
	}
}
@media screen and (min-width: 1019px) and (max-width: 1120px) {
	.customModal,
	.successModal {
		min-width: 47rem !important;
		max-width: 47rem !important;
	}
	.DeleteModal{
		min-width: 47rem !important;
		max-width: 47rem !important;
	}
}
@media screen and (min-width: 919px) and (max-width: 1020px) {
	.customModal,
	.successModal {
		min-width: 47rem !important;
		max-width: 47rem !important;
	}
	.DeleteModal{
		min-width: 47rem !important;
		max-width: 47rem !important;
	}
}
@media screen and (min-width: 819px) and (max-width: 920px) {
	.customModal,
	.successModal {
		min-width: 47rem !important;
		max-width: 47rem !important;
	}
	.DeleteModal{
		min-width: 47rem !important;
		max-width: 47rem !important;
	}
}
@media screen and (min-width: 719px) and (max-width: 820px) {
	.customModal,
	.successModal {
		min-width: 40rem !important;
		max-width: 40rem !important;
	}
	.DeleteModal{
		min-width: 40rem !important;
		max-width: 40rem !important;
	}
}
@media screen and (min-width: 619px) and (max-width: 720px) {
	.customModal,
	.successModal {
		min-width: 30rem !important;
		max-width: 30rem !important;
	}
	.DeleteModal{
		min-width: 30rem !important;
		max-width: 30rem !important;
	}
}
@media screen and (min-width: 520px) and (max-width: 620px) {
	.customModal,
	.successModal {
		min-width: 30rem !important;
		max-width: 30rem !important;
	}
	.DeleteModal{
		min-width: 30rem !important;
		max-width: 30rem !important;
	}

}

@media screen and (min-width: 440px) and (max-width: 519px) {
	.customModal,
	.successModal {
		min-width: 25rem !important;
		max-width: 25rem !important;
	}
	.DeleteModal{
		min-width: 25rem !important;
		max-width: 25rem !important;
	}
}

@media screen and (min-width: 390px) and (max-width: 439px) {
	.customModal,
	.successModal {
		min-width: 22rem !important;
		max-width: 22rem !important;
	}
	.DeleteModal{
		min-width: 22rem !important;
		max-width: 22rem !important;
	}
}
@media screen and (min-width: 0px) and (max-width: 389px) {
	.customModal,
	.successModal {
		min-width: 20rem !important;
		max-width: 20rem !important;
	}
	.DeleteModal{
		min-width: 18rem !important;
		max-width: 18rem !important;
	}
}
